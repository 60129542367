import React from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithubSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faLink, faCode, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Card, CardBody, Media, Collapse, Button } from 'reactstrap';
//import particleTracksImage from '/particle-tracks.png';
import 'particle-tracks';
import './app.scss';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  };

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  showHideText() {
    if (!this.state.collapse) return "show";
    return "hide";
  }

  componentDidMount() {
    window.ParticleTracks.init({ container: 'js-particle-tracks-container' });
  };

  linkOrBlank(link, icon) {
    if (link) {
      return (
        <a className="mx-1" href={link}>
          <FontAwesomeIcon icon={icon} size="sm"/>
        </a>);
    };
    return '';
  };

  render() {
    var projects = [
      {
        image: '/haustothehaus.png',
        heading: 'haustothehaus',
        subheading: 'creator',
        code_link: 'https://github.com/thelemonyfresh/haustothehaus',
        description: 'House music with house sounds. Live coding performance with Sonic Pi.'
      },
      {
        image: '/word-list-performance.png',
        heading: 'Anagram performance analysis',
        subheading: 'author',
        code_link: 'https://gist.github.com/thelemonyfresh/b86ad493464fdbb4ea08708c92cd21ff',
        description: "One man's journey to build an anagram finder for his Rails app, and tune its performance..."
      },
      {
        image: '/particle-tracks.png',
        heading: 'Particle Tracks',
        subheading: 'creator',
        code_link: 'https://github.com/thelemonyfresh/particle-tracks',
        description: "Javasript library simulating bubble chamber particle tracks. (This page's background)",
      },
      //{
      //   image: '/daily-career-journal.png',
      //   heading: 'Daily Career Journal',
      //   subheading: 'creator, in partnership with',
      //   collab_link: <a href="https://www.careerikigai.com">Career Ikigai</a>,
      //   link: 'https://www.dailycareerjournal.me',
      //   code_link: 'https://www.github.com/thelemonyfresh/dailycareerjournal',
      //   description: 'Record daily wins to track career growth.'
      // },
      {
        image: '/the-product-manager.png',
        heading: 'TheProductManager.io',
        subheading: 'collaborator with',
        collab_link: <a href="https://github.com/seriouslyawesome">seriouslyawesome</a>,
        link: 'https://ask.thepm.io/',
        description: 'Manage your product, not your process.'
      },
      {
        image: '/trivial-trek.png',
        heading: 'TrivialTrek.com',
        subheading: 'collaborator with',
        link: 'http://www.trivialtrek.com/',
        collab_link: <a href="https://github.com/mkompanets">mkompanets</a>,
        description: 'Weighted random ST:TNG episode selector.'
      }
    ];

    var projectCards = projects.map((project) => {
      return (
        <Media tag="li" className='m-1 border-top border-secondary' key={project.heading}>
          <Media body>
            <h4 className="mb-0">{project.heading} {this.linkOrBlank(project.link, faLink)}{this.linkOrBlank(project.code_link, faCode)}</h4>

            <small className="text-muted ml-1">
              <FontAwesomeIcon rotation={90} icon={faLevelUpAlt} className="mr-1"/>
              {project.subheading} {project.collab_link}
            </small>
            <p>{project.description}</p>
          </Media>
          <img src={project.image} alt={project.heading}className="mr-1"/>
        </Media>
      );
    });

    return (
      <Container>
        <Row className='mt-5'>
          <Col  xl='5' lg='6' md='7' sm='9' xs='12'>
            <Card className='shadow mb-2'>
              <h1 className='card-header text-center'>
                <Row className='justify-content-between'>
                  Daniel Porter
                  <div>
                    <a href="https://www.linkedin.com/in/dghporter/">
                      <FontAwesomeIcon icon={faLinkedin} size='sm' className='ml-1 text-dark'/>
                    </a>
                    <a href="https://github.com/thelemonyfresh">
                      <FontAwesomeIcon icon={faGithubSquare} size='sm' className='ml-1 text-dark'/>
                    </a>
                  </div>
                </Row>
              </h1>
              <CardBody className="py-1">
                <Row className="d-flex justify-content-between align-items-baseline">
                  <p className='text-monospace'>Developer, writer, musician.</p>
                  <Button outline color="secondary" size="sm" onClick={this.toggle}>
                    {this.showHideText() + " projects"}
                  </Button>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg='6' md='12' sm='12' xs='12'>
            <Collapse isOpen={this.state.collapse}>
              <Card body className="shadow">
                <h3>Projects</h3>
                <ul className="list-unstyled">
                  {projectCards}
                </ul>
              </Card>
            </Collapse>
          </Col>
        </Row>

      </Container>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));